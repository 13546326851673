import React from 'react';
import styled from 'styled-components';

const StarsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 12px; // Added margin to create space between number and stars
`;

const StarContainer = styled.div`
  position: relative;
  width: 20px;
  height: 100%;
  font-size: 20px;
  color: #e7e722;
`;

const StarFull = styled.span`
  color: #e7e722;
  font-size: 20px;
`;

const StarHalf = styled(StarFull)`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 50%;
`;

const StarEmpty = styled(StarFull)`
  color: rgba(231, 231, 34, 0.2);
`;

const NumberTitle = styled.span` // Fixed typo in component name
  font-size: 18px;
  font-weight: 500; // Added for better visibility
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px; // Adds consistent spacing between all elements
`;

const Rating = ({ rating, reviews }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = (rating - fullStars) >= 0.5;
    const totalStars = 5;

    return (
        <RatingContainer>
            <NumberTitle>{rating}</NumberTitle>
            
            <StarsWrapper>
                {[...Array(fullStars)].map((_, index) => (
                    <StarContainer key={index}>
                        <StarFull>★</StarFull>
                    </StarContainer>
                ))}

                {hasHalfStar && (
                    <StarContainer>
                        <StarFull>★</StarFull>
                        <StarHalf>★</StarHalf>
                    </StarContainer>
                )}

                {[...Array(totalStars - fullStars - (hasHalfStar ? 1 : 0))].map((_, index) => (
                    <StarContainer key={index}>
                        <StarEmpty>★</StarEmpty>
                    </StarContainer>
                ))}
            </StarsWrapper>

            <NumberTitle>({reviews})</NumberTitle>
        </RatingContainer>
    );
};

export default Rating;