import React, { useState } from 'react';
import styled from 'styled-components';

const TutorialContainer = styled.div`
  padding: 20px;
  overflow-y: auto;
  height: 96%;
  background-color: ${(props) => (props.darkMode ? '#333' : '#ffffff')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#000')};
`;

const CollapsibleButton = styled.button`
  background-color: ${(props) => (props.darkMode ? '#444' : '#f9f9f9')};
  color: ${(props) => (props.darkMode ? '#ddd' : '#4a4a4a')};
  cursor: pointer;
  padding: 15px;
  width: 100%;
  border: 1px solid ${(props) => (props.darkMode ? '#555' : '#e6e6e6')};
  border-radius: 5px;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconSpan = styled.span`
  font-size: 20px;
  color: ${(props) => (props.darkMode ? '#aaa' : '#0265A3')};
`;

const Content = styled.div`
  padding: 0 15px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  overflow: hidden;
  background-color: ${(props) => (props.darkMode ? '#333' : '#ffffff')};
  font-size: 14px;
  border-left: 2px solid ${(props) => (props.darkMode ? '#555' : '#0265A3')};
  margin-bottom: 10px;
`;

const Highlight = styled.span`
  background-color: ${(props) => (props.darkMode ? '#555' : '#f0f8f7')};
  padding: 3px 5px;
  border-radius: 3px;
  color: ${(props) => (props.darkMode ? '#00b894' : '#00796b')};
`;

const HighlightNote = styled.p`
  color: ${(props) => (props.darkMode ? '#F4B400' : '#F2994A')};
  font-weight: bold;
  background-color: ${(props) => (props.darkMode ? '#444' : '#FFFFFF')};
  padding: 5px;
  border-radius: 5px;
  margin: 15px 0;
`;

const CollapsibleSection = ({ title, children, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <CollapsibleButton onClick={toggleOpen} darkMode={darkMode}>
        {title}
        <IconSpan darkMode={darkMode}>{isOpen ? '-' : '+'}</IconSpan>
      </CollapsibleButton>
      <Content isOpen={isOpen} darkMode={darkMode}>{children}</Content>
    </div>
  );
};

function SideBar({ darkMode }) {
  return (
    <TutorialContainer darkMode={darkMode}>
      <h2>Using the iMaven Assistant</h2>

      <CollapsibleSection title="Process Flow" darkMode={darkMode}>
        <ol>
          <li>Identify Your Needs</li>
          <li>Gather Detailed Information</li>
          <li>Analyze and Summarize</li>
          <li>Connect to Human Advisor</li>
        </ol>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Purpose" darkMode={darkMode}>
        <p><strong>AI preps for expert advisor meeting.</strong></p>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Getting Started" darkMode={darkMode}>
        <ul>
          <li>Type queries in the bottom box.</li>
          <li>Hit <Highlight darkMode={darkMode}>Enter</Highlight> or send to submit.</li>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Information Gathering" darkMode={darkMode}>
        <ul>
          <li>AI asks about your situation.</li>
          <li>Provide <strong>honest, detailed answers</strong>.</li>
          <li>More detailed info = better results.</li>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Attaching Documents" darkMode={darkMode}>
        <ul>
          <li>AI may request relevant files.</li>
          <li>Click <Highlight darkMode={darkMode}>Attach</Highlight> to upload (5MB limit).</li>
          <li>Accepted formats: <strong>txt, pdf, docx, xlsx, xls, csv, json, md, html, epub, pptx, ppt, doc</strong>.</li>
          <li>If you can't attach now, no problem — provide files to your advisor later.</li>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Tips for Best Results" darkMode={darkMode}>
        <ul>
          <li><strong>Be specific</strong> about your needs.</li>
          <li>Share relevant details of your situation.</li>
          <li>Feel free to ask if anything is unclear.</li>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Next Steps" darkMode={darkMode}>
        <ul>
          <li>AI will summarize your information.</li>
          <li>You will receive a link to schedule a meeting with an advisor.</li>
          <li>Your advisor reviews the info before the meeting.</li>
        </ul>
        <HighlightNote darkMode={darkMode}>If you get stuck, just ask the assistant what to do next to continue!</HighlightNote>
      </CollapsibleSection>

      <CollapsibleSection title="Need Help?" darkMode={darkMode}>
        <ul>
          <li>Technical issues? Contact: <Highlight darkMode={darkMode}>support@imavenai.com</Highlight></li>
        </ul>
      </CollapsibleSection>

      <CollapsibleSection title="Privacy" darkMode={darkMode}>
        <ul>
          <li>Your information is kept <strong>confidential</strong>.</li>
          <li>Only share what you're comfortable with.</li>
          <li>Remember: Detailed info and relevant documents lead to more accurate advice!</li>
        </ul>
      </CollapsibleSection>
    </TutorialContainer>
  );
}

export default SideBar;
