import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import DOMPurify from 'dompurify';

import ChatBotIcon from './assets/chat-bot-icon.svg';
import SystemIcon from './assets/system-icon.svg';
import UserChatIcon from './assets/user-chat-icon.svg';

import ChatBotDarkIcon from './assets/chat-bot-icon-dark.svg'
import SystemDarkIcon from './assets/system-icon-dark.svg'
import UserChatDarkIcon from './assets/user-chat-icon-dark.svg'

const MessageContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.type === 'user' ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  background-color: ${(props) => {
    if (props.darkMode) {
      switch (props.type) {
        case 'user':
          return '#43618E';  // Updated blue for user messages
        case 'gpt':
          return '#4C4C4C';  // Updated gray for GPT messages
        case 'system':
          return '#545963';  // Updated gray for system messages
        default:
          return '#4C4C4C';
      }
    } else {
      switch (props.type) {
        case 'user':
          return '#007BFF';
        case 'gpt':
          return '#e0e0e0';
        case 'system':
          return '#eaf4ff';
        default:
          return '#e0e0e0';
      }
    }
  }};
  color: ${(props) => (props.darkMode ? '#ffffff' : props.type === 'user' ? 'white' : 'black')};
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  min-width: 50%;
  margin-left: ${(props) => (props.type === 'user' ? '10px' : '0')};
  margin-right: ${(props) => (props.type === 'user' ? '0' : '10px')};
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.5;
  position: relative;
  font-size: 14px;
  z-index: 0;
`;

const Icon = styled.img`
  width: 30px;
  height: 25px;
  border-radius: 50%;
  align-self: self-start;
`;

const bounce = keyframes`
  0% { opacity: 0.2; transform: translateY(0); }
  20% { opacity: 1; transform: translateY(-4px); }
  40% { opacity: 0.2; transform: translateY(0); }
`;

const LoadingDots = styled.div`
  display: inline-block;
  span {
    display: inline-block;
    animation: ${bounce} 1.4s infinite both;
    margin-right: 3px;

    &:nth-child(1) { animation-delay: 0s; }
    &:nth-child(2) { animation-delay: 0.2s; }
    &:nth-child(3) { animation-delay: 0.4s; }
    &:nth-child(4) { animation-delay: 0.6s; }
  }
`;

const LittleRightCorner = styled.div`
  position: absolute;
  z-index: 10000;
  right: -5px;
  top: 0;
  border-top: 23px solid ${(props) => (props.darkMode ? '#43618E' : '#007BFF')};
  border-right: 15px solid transparent;
  width: 0;
  height: 0;
`;

const LittleLeftCorner = styled.div`
  position: absolute;
  z-index: 10000;
  top: 0;
  left: -5px;
  border-top: 23px solid ${(props) => {
    if (props.darkMode) {
      return props.type === 'system' ? '#545963' : '#4C4C4C';
    } else {
      return props.type === 'system' ? '#eaf4ff' : '#e0e0e0';
    }
  }};
  border-left: 15px solid transparent;
  width: 0;
  height: 0;
`;

const ChatMessage = ({ message, type, loading, timestamp, darkMode }) => {
  const sanitizedHTML = DOMPurify.sanitize(message);
  const [displayMessage, setDisplayMessage] = useState(sanitizedHTML);
  const [iconUrl, seticonUrl] = useState(ChatBotIcon);

  useEffect(() => {
    switch (type) {
      case 'user':
        seticonUrl(darkMode ? UserChatDarkIcon  : UserChatIcon);
        break;
      case 'gpt':
        seticonUrl(darkMode ? ChatBotDarkIcon : ChatBotIcon);
        break;
      case 'system':
        seticonUrl(darkMode ? SystemDarkIcon : SystemIcon);
        break;
      default:
        seticonUrl(darkMode ? ChatBotDarkIcon : ChatBotIcon);
    }
  }, [darkMode]);



  useEffect(() => {
    if (loading) {
      setDisplayMessage('....');
    } else {
      setDisplayMessage(message);
    }
  }, [loading, message]);

  return (
    <MessageContainer type={type}>
      {type !== 'user' && <Icon src={iconUrl} alt={`${type} icon`} type={type} />}

      <MessageBubble type={type} darkMode={darkMode}>
        {type === 'user' && <LittleRightCorner darkMode={darkMode} />}
        {loading ? (
          <LoadingDots>
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </LoadingDots>
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: displayMessage }} />
            <div style={{ fontSize: '8px', color: darkMode ? '#ffffff99' : 'inherit' }}>
              <span>{timestamp}</span>
            </div>
          </>
        )}

        {type !== 'user' && <LittleLeftCorner type={type} darkMode={darkMode} />}
      </MessageBubble>
      {type === 'user' && <Icon src={iconUrl} alt={`${type} icon`} />}
    </MessageContainer>
  );
};

export default ChatMessage;